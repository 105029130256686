import React, {useState, useEffect} from 'react';
import {FaGithub, FaLinkedin, FaEnvelope} from 'react-icons/fa';
import ReCAPTCHA from "react-google-recaptcha";
import './Contact.scss';

const Contact: React.FC = () => {
    const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [notification, setNotification] = useState<string | null>(null);

    useEffect(() => {
        if (notification) {
            const timer = setTimeout(() => {
                setNotification(null);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [notification]);

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!recaptchaValue) {
            console.error('reCAPTCHA validation failed');
            setNotification('Please verify CAPTCHA');
            return;
        }

        const apiUrl = 'https://portfolio-backend-kry7d5z2oq-uc.a.run.app/api/verify';
        // const apiUrl = 'http://localhost:5000/api/verify';
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: recaptchaValue,
                    formData,
                }),
            });

            if (response.ok) {
                const responseBody = await response.json();
                if (responseBody.status === true) {
                    setNotification('Form submitted successfully');
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                    });
                } else {
                    setNotification('Form submission failed');
                }
            } else {
                setNotification('Form submission failed');
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            setNotification('Error during form submission');
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleDownloadResume = () => {
        const driveLink = 'https://drive.google.com/file/d/1T1qYKiW0_mISt0tN-RLLxJEFQoXHuzut/view?usp=sharing';
        window.open(driveLink, '_blank');
    };

    return (
        <section id="contact" className="contact">
            <div className="contact-content">
                <div className="social-links">
                    <h2>Socials</h2>
                    <div className="social-grid">
                        <div className="grid-item">
                            <FaGithub/>
                        </div>
                        <div className="grid-item">
                            <span className="label">Github :</span>
                        </div>
                        <div className="grid-item">
                            <a href="https://github.com/tabhang" target="_blank" rel="noopener noreferrer">tabhang</a>
                        </div>

                        <div className="grid-item">
                            <FaLinkedin/>
                        </div>
                        <div className="grid-item">
                            <span className="label">LinkedIn :</span>
                        </div>
                        <div className="grid-item">
                            <a href="https://www.linkedin.com/in/tejasa/" target="_blank"
                               rel="noopener noreferrer">tejasa</a>
                        </div>

                        <div className="grid-item">
                            <FaEnvelope/>
                        </div>
                        <div className="grid-item">
                            <span className="label">Email :</span>
                        </div>
                        <div className="grid-item">
                            <a href="mailto:tabhang@binghamton.edu">tabhang@binghamton.edu</a>
                            <a href="mailto:tabhang05@gmail.com">tabhang05@gmail.com</a>
                        </div>
                    </div>
                    <div className="download-resume">
                        <button onClick={handleDownloadResume}>Download Resume</button>
                    </div>
                </div>

                <div className="contact-form">
                    <h2>Leave a message </h2>
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange}
                                   required/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange}
                                   required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message" rows={4} value={formData.message}
                                      onChange={handleInputChange} required/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="recaptcha"></label>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_ReCAPTCHA_API_KEY || ''}
                                onChange={(value: string | null) => setRecaptchaValue(value)}
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit">Submit</button>
                        </div>
                    </form>

                    {notification && (
                        <div className="notification">
                            {notification}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default Contact;