import React, { useState } from 'react';
import './Projects.scss';

interface Project {
    title: string;
    description: string;
    githubLink?: string;
    mediaLink?: string;
}

const topProjects: Project[] = [
    {
        title: 'Email Cleaner Wizard',
        description: 'Developed and implemented an Email Cleaner Service useful in leads list filtering using Node.js and Express in TypeScript, incorporating email cleaning functionalities to identify safe, invalid and catch-all emails.',
        githubLink: 'https://github.com/tabhang/Email-Cleaner-Service',
    },
    {
        title: 'Autonomous Following Robot for Load Carrying Assistance',
        description: 'Developed an autonomous robot (July 2019 - June 2020) using ArUco markers and Kinect v2 for unique person identification and depth sensing. The robot featured a three-wheel holonomic drive system and was designed to assist in load carrying at public places. Additionally, an Android app in Java enabled control over robot functions including login, alerts, and monitoring.',
        mediaLink: 'https://drive.google.com/file/d/1Y8lhifF6oaKbZLRF7k4Q5-AAs0SXLuuD/view?usp=sharing',
    },
    {
        title: 'Credit Card Fraud Detection with Adversarial Networks',
        description: 'Implemented an Adversarial Network for fraud detection, utilizing deep learning to generate synthetic data and boost model.',
        githubLink: 'https://github.com/tabhang/Credit-Card-Fraud-Detection',
    },
    {
        title: 'DermWise',
        description: 'DermWise (HackBU_24) utilizes a React Native UI for mobile interaction, a Python backend for data processing and server logic, and a specialized model for skin condition detection. The system integrates a Sequential model with a feature extractor and dense layers. Optimized with \'adam\' and \'sparse_categorical_crossentropy\', it offers users a seamless, reliable tool for mobile-based skin condition detection.',
        githubLink: 'https://github.com/tabhang/DermWise',
    },
];

const otherProjects: Project[] = [
    {
        title: 'Autonomous Underwater Vehicle',
        description: 'Developed an Autonomous Underwater Vehicle (January 2019), achieving finalist status in an NIOT Chennai competition. Designed control logic with ROS Kinetic and Python, integrating vSLAM using Zed stereo cameras and depth sensors to reduce collision risks.',
        githubLink: 'https://github.com/tabhang/AUV-SAVe-2019',
        mediaLink: 'https://drive.google.com/file/d/15ZXcprWNom6WXPWqU88BKJ7J_SOUUe7H/view?usp=sharing',
    },
    {
        title: 'Quantum SVM for MNIST: Digit 1 vs. 5 Classification',
        description: 'Implemented a quantum support vector machine (QSVC) for binary classification of digits 1 vs 5 using Qiskit.',
        githubLink: 'https://github.com/tabhang',
    },
    {
        title: 'River waste management and public Grievance redressal System ',
        description: 'Developed a desktop application (August 2018) aimed at improving river pollution management. Utilized MySQL for data storage and Java to create an interactive GUI for analyzing waste generation data.',
        mediaLink: 'https://github.com/tabhang/River-Waste-Managment-and-Public-Grievance-Redressal-System',
    },
    {
        title: 'Quantum Random Number Generator ',
        description: 'Created a Quantum Random Number Generator (QRNG) using Qiskit, leveraging superposition and quantum measurement principles. Implemented and optimized on IBM Quantum Experience, selecting appropriate backends for real hardware execution.',
        mediaLink: 'https://github.com/tabhang/',
    },
    {
        title: 'ArUco Kinect Depth Detection',
        description: 'Detection and comparison between april tag and aruco marker using Kinect v2 Language : Python, c++ Libaries : OpenCV, Libfreenect, Pylibfreenect.',
        mediaLink: 'https://github.com/tabhang/ArUco-Kinect-Depth-Detection',
    },
];

const Projects: React.FC = () => {
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);

    const openModal = (project: Project) => {
        setSelectedProject(project);
    };

    const closeModal = () => {
        setSelectedProject(null);
    };

    const isMobileView = window.innerWidth <= 768;

    return (
        <section id="projects" className="projects">
            <h2>My Projects</h2>

            <div className="projects-container">
                <div className="project-grid">
                    {topProjects.map((project, index) => (
                        <div key={index} className="project" onClick={() => openModal(project)}>
                            <h3>{project.title}</h3>
                            <p>{project.description}</p>
                            {project.githubLink ? (
                                <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                                    GitHub Link
                                </a>
                            ) : (
                                <a href={project.mediaLink} target="_blank" rel="noopener noreferrer">
                                    View Media
                                </a>
                            )}
                        </div>
                    ))}
                </div>

                {!isMobileView && (
                    <div className="project-list">
                        {otherProjects.map((project, index) => (
                            <div key={index} className="list-item" onClick={() => openModal(project)}>
                                <h4>{project.title}</h4>
                                <p>{project.description}</p>
                                {project.githubLink ? (
                                    <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                                        GitHub Link
                                    </a>
                                ) : (
                                    <a href={project.mediaLink} target="_blank" rel="noopener noreferrer">
                                        View Media
                                    </a>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {selectedProject && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h3>{selectedProject.title}</h3>
                        <p>{selectedProject.description}</p>
                        {selectedProject.githubLink ? (
                            <a href={selectedProject.githubLink} target="_blank" rel="noopener noreferrer">
                                GitHub Link
                            </a>
                        ) : (
                            <a href={selectedProject.mediaLink} target="_blank" rel="noopener noreferrer">
                                View Media
                            </a>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};

export default Projects;
