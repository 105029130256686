import React, { useState, FormEvent, ChangeEvent, useEffect, useRef } from 'react';
import { FaRobot, FaTimes } from 'react-icons/fa';
import './SupportChat.scss';
import { run } from '../utils/genAi';

interface Message {
    text: string;
    sender: 'support' | 'user';
}

interface SupportChatProps {
    onCloseChat: () => void;
}

const SupportChat: React.FC<SupportChatProps> = ({ onCloseChat }) => {

    const [messages, setMessages] = useState<Message[]>(() => {
        const savedMessages = localStorage.getItem('supportChatMessages');
        return savedMessages ? JSON.parse(savedMessages) : [{ text: 'Hello, how may I assist you today?', sender: 'support' }];
    });

    const [inputValue, setInputValue] = useState('');
    const chatBodyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        localStorage.setItem('supportChatMessages', JSON.stringify(messages));
    }, [messages]);

    useEffect(() => {
        const handleUnload = () => {
            localStorage.removeItem('supportChatMessages');
        };

        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('unload', handleUnload);
        };
    }, []);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleSendMessage = async (e?: FormEvent) => {
        e?.preventDefault();
        if (inputValue.trim()) {
            setMessages(prevMessages => [...prevMessages, { text: inputValue, sender: 'user' }]);
            setInputValue('');

            const response = await run(inputValue);

            setMessages(prevMessages => [...prevMessages, { text: response, sender: 'support' }]);
        }
    };

    const closeChat = () => {
        onCloseChat();
    };

    // Auto-scroll to the end of the chat on messages change
    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="chat-container" onClick={(e) => e.stopPropagation()}>
            <div className="chat-header">
                <div>Tejas's AI Assistant</div>
                <span className="close-button" onClick={closeChat}>
                    <FaTimes />
                </span>
            </div>
            <div className="chat-body" ref={chatBodyRef}>
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`}>
                        {message.sender === 'support' && (
                            <span className="icon">
                                <FaRobot />
                            </span>
                        )}
                        <span>{message.text}</span>
                    </div>
                ))}
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSendMessage();
                }}
                className="message-input"
            >
                <input
                    type="text"
                    placeholder="Type your message..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the input field
                />
            </form>
        </div>
    );
};

export default SupportChat;