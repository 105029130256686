import React from 'react';
import {FaReact, FaDocker, FaJenkins} from 'react-icons/fa';
import {DiPostgresql, DiMongodb} from "react-icons/di";
import {SiMysql, SiPytorch, SiTensorflow, SiSpringboot, SiQuarkus} from "react-icons/si";
import './About.scss';
import myImage from '../assets/profile-image.jpg';

const About: React.FC = () => {
    return (
        <section id="about" className="about">
            <div className="about-content">
                {/* Left Column */}
                <div className="left-column">
                    <img src={myImage} alt="Your Name" />
                    <p>
                        Full-Stack developer currently pursuing Masters in Computer Science with a focus on Artificial Intelligence at Binghamton University. Proficient in Java, C++, Python, and diverse frameworks such as Quarkus, Spring Boot, Kafka, React, PyTorch, Qiskit etc. Well-versed in Cloud Technologies, including AWS, Docker, Kubernetes, and methodologies of CI/CD. Always eager to contribute skills and drive innovation in a dynamic environment.
                    </p>
                </div>
                {/* Right Column */}
                <div className="right-column">
                    {/* Column 1 */}
                    <div className="column">
                        {/* Add technologies and icons */}
                        <div className="technology">
                            <SiSpringboot size={30}/>
                            <span>Spring Boot</span>
                        </div>
                        <div className="technology">
                            <SiQuarkus size={30}/>
                            <span>Quarkus</span>
                        </div>
                        <div className="technology">
                            <FaReact size={30}/>
                            <span>React.js</span>
                        </div>
                    </div>
                    {/* Column 2 */}
                    <div className="column">
                        {/* Add technologies and icons */}
                        <div className="technology">
                            <SiPytorch size={30}/>
                            <span>PyTorch</span>
                        </div>
                        <div className="technology">
                            <SiTensorflow size={30}/>
                            <span>TensorFlow</span>
                        </div>
                        <div className="technology">
                            <FaDocker size={30}/>
                            <span>Docker</span>
                        </div>
                        <div className="technology">
                            <FaJenkins size={30}/>
                            <span>Jenkins</span>
                        </div>
                    </div>
                    {/* Column 3 */}
                    <div className="column">
                        {/* Add technologies and icons */}
                        <div className="technology">
                            <SiMysql size={30}/>
                            <span>MySQL</span>
                        </div>
                        <div className="technology">
                            <DiPostgresql size={30}/>
                            <span>PostgreSQL</span>
                        </div>
                        <div className="technology">
                            <DiMongodb size={30}/>
                            <span>MongoDB</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;