import React, {useState} from 'react';
import {FaRobot, FaTimes} from 'react-icons/fa';
import SupportChat from './SupportChat';
import './SupportChatBubble.scss';

const SupportChatBubble: React.FC = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isGreetingVisible, setIsGreetingVisible] = useState(true);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
        setIsGreetingVisible(false);
    };

    const closeGreeting = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsGreetingVisible(false);
    };

    return (
        <div className="chat-bubble" onClick={toggleChat}>
      <span className="icon">
        <FaRobot/>
      </span>
            {isGreetingVisible && (
                <div className="greeting-message">
                    Hi, How can I help you today?
                    <span className="close-button" onClick={closeGreeting}>
                        <FaTimes/>
                    </span>
                </div>
            )}
            {isChatOpen && <SupportChat onCloseChat={toggleChat}/>}
        </div>
    );
};

export default SupportChatBubble;