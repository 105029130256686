import React from 'react';
import Header from './Header';
import './Portfolio.scss';
import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact";
import SupportChatBubble from "./SupportChatBubble";


const Portfolio: React.FC = () => {
    return (
        <div className="portfolio">
            <Header/>
            <div className="main-content">
                <About/>
                <Projects/>
                <Contact/>
                <SupportChatBubble/>
            </div>
        </div>
    );
}

export default Portfolio;