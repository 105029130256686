import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import './Header.scss';

const Header: React.FC = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 50 && !isMobileScreen();
            setScrolled(isScrolled);
        };

        const isMobileScreen = () => {
            return window.innerWidth <= 768;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`header ${scrolled ? 'scrolled' : ''}`}>
            <h1 className={`header-title ${scrolled ? 'scrolled' : ''}`}>
                <Link to="about" smooth={true} duration={500}>
                    Tejas Abhang
                </Link>
            </h1>
            <nav>
                <ul>
                    <li>
                        <Link to="about" smooth={true} duration={500}>
                            About
                        </Link>
                    </li>
                    <li>
                        <Link to="projects" smooth={true} duration={500}>
                            Projects
                        </Link>
                    </li>
                    <li>
                        <Link to="contact" smooth={true} duration={500}>
                            Contact
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;